



















































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate'
import { readUserProfile, readAllProjects, readHasAdminAccess } from '@/store/main/getters';
import { Role } from '@/enums';
import { dispatchGetProjects, dispatchCreateProject } from '@/store/main/actions';
import { IProjectCreate } from '@/interfaces';

@Component
export default class ProjectsHome extends Vue {

  private openCreateDialog = false; // toggle for edit or create dialog
  private newProjectName = "";
  private newProjectDesc = "";
  private creatorFilter = 0;
  private projectCreateError = "";

  $refs!: {
    dialogForm: InstanceType<typeof ValidationObserver>;
  };

  public async mounted() {
    const f = this.$route.query.f ? this.$route.query.f[0] || "" : ""
    this.creatorFilter = parseInt(f) || 0;
    this.loadProjects();
  }

  private async loadProjects() {
    await dispatchGetProjects(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public get isAdmin() {
    return readHasAdminAccess(this.$store); 
  }

  public get isCreator() {
    return this.userProfile?.role.id == Role.AICREATOR; 
  }

  get projects() {
    return readAllProjects(this.$store)
      .sort((a, b) => b.created_on.getTime() - a.created_on.getTime());
  }

  private formatDateForDisplay(date: Date): string {
    // if same day than today, show only time 
    if (date.toLocaleDateString() == new Date().toLocaleDateString()) {
      return date.toLocaleTimeString();
    }
    return date.toLocaleDateString();
  }

  public clearAndCloseDialog() {
    this.$refs.dialogForm.reset();
    this.openCreateDialog = false;
    this.newProjectName = "";
    this.newProjectDesc = "";
    this.projectCreateError = "";
  }

  public async submitNewProject() {
    if (this.newProjectName) {
      const proj: IProjectCreate = {
        name: this.newProjectName.trim(),
        description: this.newProjectDesc.trim(),
      }
      try {
        await dispatchCreateProject(this.$store, proj)
        this.clearAndCloseDialog();
      } catch (e) {
        console.error(e.message);
        this.projectCreateError = e.message;
      }
    }
  }

}
