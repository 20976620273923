




































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile, readAllProjects, readHasAdminAccess } from '@/store/main/getters';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';
import { dispatchGetProjects } from '@/store/main/actions';
import { Role } from '@/enums';

@Component
export default class Dashboard extends Vue {

  public async mounted() {
    await dispatchGetProjects(this.$store);
    await dispatchGetUsers(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get isCreator() {
    return this.userProfile?.role.id == Role.AICREATOR; 
  }

  get projects() {
    return readAllProjects(this.$store);
  }

  get users() {
    if (this.isAdmin) {
      return readAdminUsers(this.$store);
    } else return []
  }

  get greetedUser() {
    const userProfile = this.userProfile;
    if (userProfile) {
      if (userProfile.display_name) {
        return userProfile.display_name;
      } else if (userProfile.user_id) {
        return userProfile.user_id;
      } else {
        return userProfile.email;
      }
    } else return "guest"
  }


}
